.host {
  position: relative;
  min-width: 220px;
  user-select: none;
}

.control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  cursor: pointer;
  min-height: 37px;

  &:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
}

.arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;

  .isOpen & {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
}

.menu {
  cursor: pointer;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  display: none;

  .isOpen & {
    display: block;
  }
}

.option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  display: block;
  padding: 8px 10px;

  &:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:hover {
    background-color: #f2f9fc;
    color: #333;
  }

  &.isSelected {
    background-color: #f2f9fc;
    color: #333;
  }
}
