@import '../../scss/variables';

.host {
  padding: 0 16px;
  position: fixed;
  z-index: 5;
  width: 100%;
  height: $header_height;
  display: flex;
  align-items: center;
  background: $color-primary;
  color: white;

  &.transparent {
    background: transparent;
  }
}

.sidebarTrigger {
  cursor: pointer;
  font-size: 1.3em;
  display: none;
  margin-right: 16px;

  @media (max-width: $breakpoint-md) {
    display: block;
  }
}

.logo {
}

.navLeft {
  display: flex;
  margin-left: 16px;

  a {
    display: block;
    color: $header_text-color;
    position: relative;
    height: $header_height;
    line-height: $header_height;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      bottom: 0;
      background: $header_navlink-active-border-color;
      transition: all 0.3s ease-out;
    }

    &:hover,
    &.active {
      &::after {
        width: 100%;
        height: 4px;
        left: 0;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

.navRight {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.contactBtn {
  color: $header_text-color;
  padding: 0.6em 1em;
  border-radius: 3px;
  border: 2px solid $header_text-color;
  letter-spacing: 0.3pt;
  margin-right: 16px;

  svg {
    padding-right: 0.6em;
  }
}

@media (max-width: $breakpoint-md) {
  .searchBoxFocused {
    .navLeft,
    .contactBtn {
      display: none;
    }
  }
}
