.host {
  background: white;
  padding: 1em;
  margin: 1em 0;
  box-shadow: 0 0px 0px -1px rgba(0, 0, 0, 1), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  line-height: 1.2em;

  h3 {
    font-size: 1em !important;
    margin: 0 0 0.3em !important;
  }
}
