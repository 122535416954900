@import '../../../scss/variables';

.host {
  position: absolute;
  z-index: 3;
  top: calc(#{$header-height} + 10px);
  transform: translateX(100%);
  opacity: 0;
  transition: all 300ms ease-out;
  min-width: 400px;
  max-width: 500px;
  min-height: 50px;
  color: $main_text-color;
  background: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  @media (max-width: $breakpoint-md) {
    width: 100%;
    min-width: 100%;
  }

  h1 {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    background: $color-primary;
    color: white;
    height: 40px;
    line-height: 40px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    max-height: calc(100vh - 64px - 10px - 40px - 10px - 35px - 90px);
    overflow-y: auto;
  }

  li {
    border-bottom: 1px solid #ccc;
  }

  a {
    display: block;
    padding: 1em;
    color: $main_text-color;
    &:hover {
      background: #eee;
    }
  }

  em {
    color: $color-primary;
    font-weight: bold;
  }

  p {
    // no result
    text-align: center;
    padding: 1em;
  }
}

.inner {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  padding: 5px;
  border-radius: 3px;
  margin-right: 1em;
  overflow: hidden;
  height: 50px;
  display: flex;
  img {
    margin: auto;
    max-width: 100%;
    max-height: 40px;
  }
}

.content {
}

.description,
.technology,
.program {
  display: block;
  margin-top: 0.6em;
  font-size: 0.9em;
  line-height: 1.2em;
}

.focused.opened {
  transform: translateX(0);
  right: 1em;
  opacity: 1;

  @media (max-width: $breakpoint-md) {
    right: 0;
  }
}

.searchByAlgolia {
  padding: 0.5em;
  text-align: right;
  background: #f8f8ff;
  img {
    max-width: 105px;
  }
}

.loading {
  height: 50px;
  display: flex;

  svg {
    margin: auto;
    color: #233810;
  }
}
