@import '../../../../scss/variables';

.host {
  display: block;
  background: $color-primary;
  padding: 1em;
  border-radius: 5px;
  color: white;
  text-align: center;
  cursor: pointer;
  margin-bottom: 1em;

  svg {
    padding-left: 0.7em;
  }
}
