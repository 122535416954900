.host {
  position: relative;
  margin: 3em auto 1em;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    width: 90%;
    left: 5%;
    height: 1px;
    background: rgba(0, 0, 0, 0.5);
  }

  span {
    position: relative;
    padding: 0 1em;
    background: white;
  }
}
