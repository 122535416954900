.host {
  margin: 0.7em 0;

  .info {
    margin: 0.6em 0.5em 0.6em 0;
  }

  h6,
  strong {
    font-weight: bold;
    color: #444;
    font-size: 1;
  }

  h6 {
    display: inline-block;
  }

  h6 + p {
    display: inline-block;
    margin-left: 0.3em;
  }

  p {
    margin: 0.3em 0;
  }
}
