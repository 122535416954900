.host {
  padding-top: 0.5em;
  padding-bottom: 1.5em;

  i {
    padding: 0 0.5em;
  }

  a:first-of-type i {
    padding-left: 0;
  }

  [class~='fa-angle-right'] {
    margin-left: 5px;
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.87);
    transform: scale(0.7);
  }
}
