@import '../../../scss/variables';

.host {
  div {
    max-width: 900px;
    margin: 3em auto;
    padding: 0 3em;
  }

  h3 {
    font-weight: bold;
    font-size: 2em;
    margin: 0 0 0.8em;
  }

  p {
    font-size: 1.1em;
    line-height: 1.4em;
    margin: 0;
  }

  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    div:last-child {
      grid-column-end: span 2;
    }
  }
}
