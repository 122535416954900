@import '../../scss/variables';

.host {
  h1 {
    text-align: center;
    color: $color-primary;
    font-size: 4em;
    padding: 0.5em;
  }

  p {
    text-align: center;
    font-size: 1.3em;
  }
}
