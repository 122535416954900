@import '../../scss/variables';

.host {
  padding: 1em;
  position: relative;
  z-index: 2;
  text-align: center;
  background: $color-primary;
  color: white;

  @media (min-width: $breakpoint-md) {
    padding: 0.5em;
  }
}

.inner {
  @media (min-width: $breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  @media (min-width: $breakpoint-md) {
    display: flex;
    align-items: center;
    h6 {
      order: 2;
    }
  }
  svg {
    margin: 0 1em;
    height: 60px;
  }
}
.terms {
  text-align: left;
  font-size: 0.9em;

  @media (max-width: $breakpoint-md) {
    text-align: center;
  }

  p {
    margin: 1em 0;
  }

  .datadock {
    line-height: 1.2em;
  }
}

.links {
  margin-right: 70px;

  @media (max-width: $breakpoint-md) {
    margin-right: 0;
  }

  a {
    @media (min-width: $breakpoint-md) {
      display: inline;
      padding: 0 1em;
    }
    display: block;
    font-size: 0.8em;
    color: white;
    padding: 1em 0;
  }
}
