.host {
  border-top: 1px solid #e7e7e7;
  margin: 0 16px;
  padding: 16px 0;

  h3 {
    font-size: 1.3em;
  }
}

.rating {
  color: #ffa600;
}

.item {
  h4 {
    margin-bottom: 0.2em;
  }
  p {
    margin: 0.4em 0;
  }
}
