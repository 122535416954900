@import '../../../scss/variables';

.host {
  display: flex;
  label {
    font-size: 1.2em;
    cursor: pointer;
  }
}

.textfield {
  margin-left: 5px;
  display: inline-block; // For text-align right on .search-box
  width: 0; // invisible by default
  transition: width 0.3s ease-out;

  .focused & {
    width: 200px; // desktop size
  }

  input {
    width: 100%;
    font-size: 1em;
    padding: 4px 0;
    background: none;
    outline: none;
    color: $header-text-color;
    border: none;
    border-bottom: 1px solid $header-text-color;
    -webkit-appearance: none;
    border-radius: 0;
  }
}
