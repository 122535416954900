@import '../../../scss/variables';

.host {
  overflow: hidden;
  z-index: 1;
  flex-direction: column;
  background: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #444;
  }

  h1 {
    font-size: 1.6em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.15em;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }

  li {
    margin: 0.5em 0;
    line-height: 1.4em;
    position: relative;
    padding-left: 20px;

    &::before {
      content: '• ';
      color: #818785;
      position: absolute;
      left: 0;
    }
  }
}

/************************ HEADER *******************************/
.header {
  text-align: center;

  .logo {
    height: 180px;
    flex: 1;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 80%;
      max-height: 80px;
    }
  }

  .name {
    background: white;
    padding: 0 16px;
    color: black;

    h1 {
      font-weight: normal;
      font-size: 1em;
      margin: 0;
      padding: 16px 0;

      .bigCard & {
        font-size: 1.8em;
      }
    }
  }
}

.content {
  overflow: hidden; // for animation only
}

.grid {
  display: flex;
  border-top: 1px solid #e7e7e7;
  margin: 0 16px;
  padding: 16px 0;

  @media (max-width: $breakpoint-md) {
    margin: 0 8px;
    padding: 8px 0;
    flex-direction: column;
  }

  h2 {
    padding-bottom: 0.3em;
    border-bottom: 1px solid #e7e7e7;
  }
}

.main {
  padding-right: 16px;
  flex: 2;

  @media (max-width: $breakpoint-md) {
    flex: auto;
    padding-right: 0;
    padding-bottom: 8px;
  }
}

.aside {
  flex: 1;
  @media (max-width: $breakpoint-md) {
    flex: auto;
  }
}

.description {
  margin-top: 0;
  line-height: 1.4em;
  white-space: pre-line;
}
