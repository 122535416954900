@import '../../../../../scss/variables';

.host {
  a {
    color: #333;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.4em 0.2em;
    transition: all 0.2s ease-out;
    margin: 0.4em 0;
    &:hover {
      border-color: $color-primary;
      background: $color-primary;
      color: white;
    }
  }
}

.day {
  font-weight: bold;
  font-size: 1.5em;
  padding: 0 1em;
}

.monthAndLocation {
  font-size: 0.8em;
}
