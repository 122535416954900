@import '../../scss/variables';

.host {
}

.navigation {
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
  }
}

.categoryDropdown {
  min-width: 220px;

  @media (max-width: $breakpoint-md) {
    margin: 0 0 1em;
  }
}

.routes {
  position: relative;
  // TODO scss variables :
  // 100vh - headerHeight - pagePaddingY - navigationHeight
  min-height: calc(100vh - 147px);

  &.animating > * {
    position: absolute;
    width: 100%;
  }
}
