@import '../../../scss/variables';

.host {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  grid-template-columns: 1fr;

  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: $breakpoint-xl) {
    grid-template-columns: repeat(5, 1fr);
  }
}
