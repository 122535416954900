@import '../../../../scss/variables';

.host {
  border-top: 1px solid #e7e7e7;
  margin: 0 16px;
  padding: 16px 0;

  @media (max-width: $breakpoint-md) {
    margin: 0 8px;
    padding: 8px 0;
  }

  h3 {
    margin-top: 8px;
  }
}

.list {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  grid-template-columns: 1fr;

  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}
