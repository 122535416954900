@import '../../../scss/variables';

@media (min-width: $breakpoint-md) {
  .flexContainer {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 50%;
      margin: 0.5em 0;

      &:nth-child(odd) {
        padding-right: 20px;
      }

      &:nth-child(even) {
        padding-left: 20px;
      }
    }
  }
}
