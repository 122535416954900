@import '../../../../../scss/variables';

$margin: 18px;

.host {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  margin-bottom: $margin;
  flex-direction: column;
  min-height: 180px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  .logo {
    flex: 1;
    display: flex;

    img {
      margin: auto;
      height: 70px;
    }
  }

  strong {
    background-color: #fff;
    text-align: center;
    color: #444;
    padding: 1em;
  }
}
