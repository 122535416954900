@import '../../../scss/variables';

.host {
  margin-top: 1em;
  padding: 0.5em 0.6em;
  font-size: 1.1em;
  border: none;
  outline: none;
  background: $color-primary;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  min-width: 100px;

  &:disabled {
    cursor: not-allowed;
    background: lighten($color-primary, 20%);
  }
}
