.fade-enter,
.fade-exit {
  z-index: 3; // over footer
  position: absolute;
  width: 100%;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms ease;
}
