.prepend-icon {
  display: flex;
  align-items: baseline;
  margin: 0.4em 0;

  span:not(:last-child) {
    margin-right: 5px;
    text-align: center;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
