body {
  font-family: $font;
  background: $color-primary; // for scrolling bounce (mac os at least)
}

#app {
  position: relative;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  margin-top: $header-height;
  color: $main_text-color;
  background: white;
  position: relative;
  a {
    color: $color-primary;
  }
}

a {
  text-decoration: none;
}

/********** Content *********************/
.page {
  background: white;
}

.page:not(.page-home) {
  padding: 16px;

  @media (max-width: $breakpoint-md) {
    padding: 8px;
  }
}

@media (min-width: $breakpoint-md) {
  .page:not(.page-home),
  .breadcrumb {
    width: 100%;
    // padding: 1em calc((100% - #{$breakpoint-md - 40px}) / 3); // margin auto doesn't apply between page fade (bc of position fixed)
  }
}

/************ Fade between pages ***********/
main > span {
  // Span is added by SwitchCSSTransitionGroup, cf /js/app.js
  display: block;
  height: 100%;
}
