@import '../../../scss/variables';

.host {
  margin: 1em 0;

  label {
    display: block;
    margin: 0.3em 0;
  }

  input,
  textarea {
    font-size: 0.9em;
    width: 100%;
    padding: 0.5em;
    outline: none;
    border: 1px solid $color-primary;
    border-radius: 3px;
    background: #f9fcf4;
    color: #555;
    font-family: 'Lato', sans-serif;
  }

  .formErrors {
    font-size: 0.8em;
    color: red;
    margin: 0.3em 0;
    display: none;
  }

  &.hasErrors {
    input,
    textarea {
      border-color: red;
      background: #fcf4f5;
    }

    .formErrors {
      display: block;
    }
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  .row {
    margin: 1em 0;
  }
}
