.rating {
  padding-right: 0.8em;
  color: #ffa600;
  > span > span {
    margin: 0 0.1em;
    font-size: 1.5em;
  }
}

.weight {
  color: #666;
  margin-top: 0.7em;
  font-size: 0.85em;
  line-height: 1.7em;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
