@import '../../../../scss/variables';

.day {
  &:not(:last-of-type) {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 1em;
  }

  &:last-of-type {
    @media (max-width: $breakpoint-md) {
      padding-bottom: 1em;
      border-bottom: 1px solid #e7e7e7;
    }
  }
}

.chapters {
  column-count: 1;

  @media (min-width: $breakpoint-lg) {
    column-count: 2;
  }
}

.chapter {
  margin: 0 0 1em;

  h4 {
    margin: 0 0 0.4em;
  }
}
