.host {
  font-weight: bold;
  color: #444;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid #bbb;
  padding: 0 0.5em 0.5em;
  margin: 0 0 1em !important;
  font-size: 1em !important;
}
