@import '../../../../scss/variables';

.host {
  border-top: 1px solid #e7e7e7;
  font-size: 0.8em;
  color: #757575;
  margin: 0 16px;
  padding: 16px 0;

  @media (max-width: $breakpoint-md) {
    margin: 0 8px;
    padding: 8px 0;
  }
}
