$font: 'Lato', sans-serif;

$color-primary: #548131;

$header_height: 64px;
$header_text-color: white;
$header_navlink-active-border-color: #8bc34a;

$main_text-color: rgba(0, 0, 0, 0.87);

$home-landing_text-color: white;
$home-landing_button-bg-color: #1d79be;

$loading-bar__background: #5a8435;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
