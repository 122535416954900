@import '../../scss/variables';

.host {
  padding-bottom: 1em;

  h2 {
    margin: 0 0 1em;
  }

  address {
    font-style: normal;
    margin: 1em 0 2em;
  }

  @media (min-width: $breakpoint-md) {
    display: flex;
    flex-direction: row;
  }
}

.mapContainer {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: $breakpoint-md) {
  .col {
    flex-grow: 1;

    &:first-child {
      margin-right: 40px;
    }
  }
}
