@import '../../scss/variables';

.host {
  width: 250px;
  position: fixed;
  z-index: 6;
  top: 0;
  bottom: 0;
  left: 0;
  background: white;
  transform: translate(-100%);
  transition: transform 0.3s ease-out;

  nav {
    a {
      display: block;
      padding: 1em;
      color: $color-primary;
      border-top: 1px solid #eee;

      &.active,
      &:hover {
        background: $color-primary;
        color: white;
      }
    }
  }
}

.header {
  background: $color-primary;
  text-align: center;
  padding: 1em;
}

.logo img {
  width: 45px;
}

.opened {
  transform: translate(0);
}
