@import '../../../scss/variables';

.host {
  height: 100vh;
  display: flex;
  padding-top: 64px;

  // top + margin-bottom -> allow smooth fade (difficult with a margin-top)
  position: relative;
  top: -$header-height;
  margin-bottom: -$header-height;

  background: $color-primary;
}

.particles {
  position: absolute;
  opacity: 0.6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & > div {
    width: 100%;
    height: 100%;
  }
}

.text {
  margin: auto;
  text-align: center;
  position: relative;
  color: $home-landing_text-color;

  h1 {
    font-size: 4em;
    margin: 0;

    @media (max-width: $breakpoint-md) {
      font-size: 3em;
    }
  }

  p {
    font-size: 1.7em;
    line-height: 1.3em;
    max-width: 650px;
    margin: 1em auto 2em;
  }

  a {
    display: inline-block;
    padding: 0.8em 1.3em;
    font-weight: bold;
    font-size: 1.3em;
    background: $home-landing_button-bg-color;
    color: $home-landing_text-color;
    border-radius: 5px;
    border: 2px solid $home-landing_text-color;

    svg {
      padding-left: 0.5em;
    }
  }

  @media (max-width: $breakpoint-md) {
    font-size: 0.8em;
    padding-left: 1em;
    padding-right: 1em;
  }
}
