.host {
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2em;
  }

  li {
    padding: 2em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 150px;
    max-height: 120px;
    opacity: 1;
    transition: opacity 0.3s;
  }

  img[data-src] {
    opacity: 0;
  }
}
